import { axiosGet, axiosPost } from "@/api/request"
import apis from "@/api/apis"

export function handleGetOrderPrice(data) {
    return axiosGet(apis.getOrderPrice, data);
}

//获取订单凭证
export function handleGetOrderVoucher(data) {
    return axiosGet(apis.orderVoucher, data)
}