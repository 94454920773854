<template>
    <div class="detail_container flex_start">
        <div class="detail_wrap">
            <div class="detail_top">
                <div class="wrap_txt">
                    <span style="font-size: 26px;color: #f54123;">￥{{costData.estimate_price}}</span>
                    <span>运输总里程：{{costData.distance}}公里</span>
                </div>
                <div style="font-size: 14px;color: #f54123;margin: 10px 0;text-align:left;">本次订单合计</div>
                <div class="wrap_tips">上述费用为运费信息, 若产生高速费 / 停车费 / 入仓费 / 装卸费 , 订单完成后请用户额外支付结算; 若涉及逾时等候费用 , 请与司机线下协商解决;</div>
            </div>
            <div class="detail_bottom">
                <div class="flex_between_center cost_f">
                    <span>运费小计</span>
                    <span v-if="costData.back_car==0">￥{{costData.zhuanche_money}}</span>
                    <span v-else>￥{{costData.huichengche_money}}</span>
                </div>
                <div class="flex_between_center cost_text" v-if="costData.back_car==0">
                    <span>起步价（小货车）</span>
                    <span>￥{{costData.init_money}}</span>
                </div>
                <div class="flex_between_center cost_text" v-if="costData.back_car==0">
                    <span>里程（{{costData.distance}}公里）</span>
                    <span v-if="parseFloat(costData.distance) > parseFloat(costData.init_km)">￥{{costData.unit_money*(costData.distance-costData.init_km)}}</span>
                    <span v-else>¥ 0</span>
                </div>
                <div class="flex_between_center cost_text" v-if="costData.pendant_name">
                    <span>{{costData.pendant_name}}</span>
                    <span>￥{{costData.pendant_price}}</span>
                </div>
                <div class="flex_between_center cost_text" v-if="costData.coupon_money>0">
                    <span>优惠券</span>
                    <span>-￥{{costData.coupon_money}}</span>
                </div>
                <div class="detail_tips" v-if="costData.back_car==0">
                    起步{{costData.init_km}}公里不计费, 后续 {{costData.unit_money}}元/1公里
                </div>
            </div>
            <div class="detail_bottom">
                <div class="flex_between_center cost_f">
                    <span>代付费小计</span>
                    <span>￥{{priceData.other_free_total||0}}</span>
                </div>
                <div class="flex_between_center cost_text">
                    <span>高速费</span>
                    <span>￥{{costData.expressway_money||0}}</span>
                </div>
                <div class="flex_between_center cost_text">
                    <span>停车费</span>
                    <span>￥{{costData.park_money||0}}</span>
                </div>
                <div class="flex_between_center cost_text">
                    <span>入仓费</span>
                    <span>￥{{costData.in_storage_money||0}}</span>
                </div>
                <div class="flex_between_center cost_text">
                    <span>装卸费</span>
                    <span>￥{{costData.carry_money||0}}</span>
                </div>
                <div class="flex_between_center cost_text">
                    <span>其他费用</span>
                    <span>￥{{costData.other_money||0}}</span>
                </div>
                <div class="detail_tips">
                    如无产生,本次运输不会计入相关费用
                </div>
            </div>
        </div>
        <div class="proof_wrap" v-if="order_sn != '' && voucherData != ''">
            <div class="proof_item" v-if="voucherData && voucherData.finish.length !=0">
                <div class="proof_name">订单凭证</div>
                <div class="proof_time">2021-01-12 18:01</div>
                <div class="proof_imgs flex_start">
                    <img class="p_img" src="@assets/imgs/0003311.jpg" alt="">
                    <img class="p_img" src="@assets/imgs/0003311.jpg" alt="">
                    <img class="p_img" src="@assets/imgs/0003311.jpg" alt="">
                </div>
            </div>
            <div class="proof_item" v-if="voucherData && voucherData.help_pay.length !=0">
                <div class="proof_name">代付款凭证</div>
                <div class="proof_time">2021-01-12 18:01</div>
                <div class="proof_imgs flex_start">
                    <img class="p_img" src="@assets/imgs/0003311.jpg" alt="">
                    <img class="p_img" src="@assets/imgs/0003311.jpg" alt="">
                    <img class="p_img" src="@assets/imgs/0003311.jpg" alt="">
                </div>
            </div>
            <div class="proof_item" v-if="voucherData && voucherData.shipment.length !=0">
                <div class="proof_name">装车凭证</div>
                <div class="proof_time">2021-01-12 18:01</div>
                <div class="proof_imgs flex_start">
                    <img class="p_img" src="@assets/imgs/0003311.jpg" alt="">
                    <img class="p_img" src="@assets/imgs/0003311.jpg" alt="">
                    <img class="p_img" src="@assets/imgs/0003311.jpg" alt="">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { handleGetOrderPrice,handleGetOrderVoucher} from '@api/priceDetail'
export default {
    data() {
        return {
            costData:{},
            priceData:{},
            order_sn:'',
            voucherData:'',     //订单凭证数据
        }
    },
    created() {
        let params = this.$route.params;
        if(Object.keys(params).length > 0){
            if(params.datas){
                this.costData = params.datas;
            }
            if(params.order_sn){
                this.order_sn = params.order_sn;
                this.onGetOrderPrice(params.order_sn,params.back_car)
                this.onGetOrderVoucher(params.order_sn)
            }
        }
    },
    mounted() {

    },
    methods: {
        onGetOrderPrice(order_sn, back_car) {
            let datakey = {}
            datakey['order_sn'] = order_sn
            handleGetOrderPrice(datakey).then(datas=>{
                if (datas.status == 0) {
                    let costData = datas.data.order_free
                    costData.back_car = back_car
                    costData.zhuanche_money = datas.data.freight_total
                    costData.huichengche_money = datas.data.freight_total
                    costData.init_km = datas.data.freight.init_km || 0
                    costData.estimate_price = datas.data.total
                    costData.pendant_name = datas.data.pandent_name
                    costData.pendant_price = datas.data.pandent_price
                    this.costData = costData;
                    this.priceData = datas.data;
                }
            }).catch(err=>{
                console.log(err)
                this.$message.error(err.msg)
            })
        },
        onGetOrderVoucher(order_sn){
            let datakey = {};
            datakey['order_sn'] = order_sn;
            handleGetOrderVoucher(datakey).then(datas=>{
                if(datas.status == 0){
                    this.voucherData = datas.data
                }
            }).catch(err=>{
                console.log(err)
            })
        },
    },
}
</script>
<style lang="scss">
    .detail_wrap {
        width: 40%;
        margin-right: 20px;
        background: #fff;
        padding: 20px;
        .detail_top {
            padding: 0 10px 20px;
            border-bottom: 1px dashed #ddd;
        }
        
        .wrap_txt {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
        }
        
        .wrap_tips {
            font-size: 14px;
            color: #aaa;
        }
        
        .detail_bottom {
            padding-top: 20px;
        }
        
        .detail_tips {
            font-size: 13px;
            color: #aaa;
            text-align: right;
            letter-spacing: 0.5px;
        }
        .cost_f{
            margin-bottom: 10px;
            color: #f54123;
            font-size: 14px;
        }
        .cost_text{
            padding: 0 0 10px 20px;
            color: #333;
            font-size: 14px;
        }
    }
    .proof_wrap{
        width: 60%;
        background: #fff;
        padding: 20px;
        .proof_item{
            width: 100%;
            margin-bottom: 20px;
        }
        .proof_item:last-child{
            margin-bottom: 0;
        }
        .proof_name{
            font-size: 14px;
            text-align: left;
            color: #333;
        }
        .proof_time{
            color: #aaa;
            font-size: 13px;
            padding: 6px 0 10px 0;
            text-align: left;
            border-bottom: 1px dashed #ddd;
        }
        .proof_imgs{
            flex-wrap: wrap;
            padding-top: 10px;
        }
        .p_img{
            width: 140px;
            height: 180px;
            margin-right: 10px;
        }
    }
    
</style>